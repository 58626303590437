<template>
  <a-modal
    :visible="true"
    title="Actualizar mi usuario"
    @ok="handleOk"
    @cancel="handleCancel"
    @close="closeModal"
    class="max-w-sm"
    okText="Guardar"
    :maskClosable="false"
  >
    <a-form :model="formState" :rules="rules" ref="formRef" layout="vertical">
      <a-form-item v-if="typeEdit === 'celular'" label="Celular" name="celular">
        <a-input
          v-model:value="formState.celular"
          placeholder="Celular"
          @change="onChangeCelular"
        />
      </a-form-item>
      <a-form-item v-if="typeEdit === 'correo'" label="Correo" name="correo">
        <a-input v-model:value="formState.correo" placeholder="Correo" />
      </a-form-item>
      <a-alert v-if="hasError" class="mt-4" :description="hasError" type="error" />
    </a-form>
  </a-modal>
</template>

<script>
import { reactive, toRaw, ref } from "vue";
import { notification } from "ant-design-vue";
import UserApi from "@/api/user";
import { useStore } from "vuex";

export default {
  props: {
    usuario: {
      type: Object,
      default: null,
      required: true
    },

    idusuario: {
      type: String,
      default: null,
      required: true
    },

    typeEdit: {
      type: String,
      default: "",
      required: true
    }
  },
  emits: ["closeModal"],
  setup(props, { emit }) {
    const formState = reactive(props.usuario);
    /* eslint-disable  vue/no-setup-props-destructure*/
    const correo = props.usuario.correo;
    const formRef = ref();
    const hasError = ref(false);
    const store = useStore();

    const closeModal = () => {
      emit("closeModal");
    };

    const MAX_LENGTH_CELULAR = 9;
    const REGEX_NUMEROS = "^[0-9]+$";

    const rules = {
      correo: [
        {
          required: true,
          message: "El campo correo no puede estar vacío",
          trigger: "blur"
        },
        {
          type: "email",
          message: "Formato de correo inválido"
        }
      ],
      celular: [
        {
          max: MAX_LENGTH_CELULAR,
          message: "La cantidad máxima de caracteres es de 9",
          trigger: "blur"
        }
      ]
    };

    const onChangeCelular = (e) => {
      formState.celular = e.target.value.slice(0, MAX_LENGTH_CELULAR);
      if (!new RegExp(REGEX_NUMEROS, "g").test(e.target.value)) {
        formState.celular = e.target.value.slice(0, -1);
      }
    };

    const handleOk = () => {
      formRef.value
        .validate()
        .then(async () => {
          const payload = toRaw(formState);
          const isUnique = payload.correo !== correo;

          UserApi.editCelularCorreo(payload, props.idusuario, isUnique)
            .then(() => {
              const user = store.state.user.usuario;
              user.correo = payload.correo;
              user.celular = payload.celular;

              store.dispatch("user/setUsuario", user);

              notification.success({
                message: "Operación exitosa",
                description: "Los datos se han actualizado correctamente."
              });
              closeModal();
            })
            .catch((err) => {
              hasError.value = err.message;
            });
        })
        .catch((err) => console.log(err));
    };

    const handleCancel = () => {
      closeModal();
    };

    return {
      handleOk,
      closeModal,
      handleCancel,
      formState,
      formRef,
      hasError,
      rules,
      onChangeCelular
    };
  }
};
</script>
