<template>
  <a-page-header @back="() => $router.go(-1)" title="MI CUENTA" class="pt-0 px-0">
    <template #extra>
      <a-breadcrumb>
        <a-breadcrumb-item><router-link to="/menu">Menu</router-link></a-breadcrumb-item>
        <a-breadcrumb-item>Mi cuenta</a-breadcrumb-item>
      </a-breadcrumb>
    </template>
  </a-page-header>

  <div class="container mx-auto">
    <div class="md:flex no-wrap md:-mx-2 gap-2">
      <div class="w-full mb-5 md:w-3/12 md:mx-2">
        <div class="bg-white p-3 border-t-4 border-green-400 rounded">
          <div class="image overflow-hidden text-8xl py-3 text-center">
            <UserOutlined />
          </div>
          <h1 class="text-gray-900 font-bold text-xl leading-8 my-1 text-center">
            {{ shortNameUser }}
          </h1>
          <p class="text-center text-gray-400">DNI: {{ documentNumber }}</p>
          <ul class="text-gray-600 hover:text-gray-700 py-2 px-3 mt-3 divide-y rounded">
            <li class="flex items-center py-3">
              <span>Estado</span>
              <span class="ml-auto"
                ><span class="bg-green-500 py-1 px-2 rounded text-white text-sm">Activo</span></span
              >
            </li>
            <li class="flex items-center py-3">
              <span>Miembro desde</span>
              <span class="ml-auto">{{ miembroDesde }}</span>
            </li>
          </ul>
        </div>
      </div>
      <div class="w-full md:w-9/12">
        <div class="bg-white p-3 shadow-sm rounded">
          <div class="flex items-center space-x-2 mb-3 font-semibold text-gray-900 leading-8">
            <span clas="text-green-500">
              <UserOutlined />
            </span>
            <span class="ml-2 tracking-wide">INFORMACIÓN BÁSICA</span>
          </div>

          <a-list size="large">
            <a-list-item class="flex flex-col md:flex-row justify-between">
              <span class="md:font-semibold">Nombres</span>
              <span class="font-bold md:font-normal">{{ fullName }}</span>
              <a-button type="ghost" class="mt-2 md:mt-0" shape="round" disabled>
                Cambiar
              </a-button>
            </a-list-item>
            <a-list-item class="flex flex-col md:flex-row justify-between">
              <span class="md:font-semibold">Celular</span>
              <span class="font-bold md:font-normal">{{ usuario.celular }}</span>
              <a-button
                type="ghost"
                class="mt-2 md:mt-0"
                shape="round"
                @click="showModal('celular')"
              >
                Cambiar
              </a-button>
            </a-list-item>
            <a-list-item class="flex flex-col md:flex-row justify-between">
              <span class="md:font-semibold">Correo</span>
              <span class="font-bold md:font-normal">{{ usuario.correo }}</span>
              <a-button
                type="ghost"
                class="mt-2 md:mt-0"
                shape="round"
                @click="showModal('correo')"
              >
                Cambiar
              </a-button>
            </a-list-item>
            <a-list-item class="flex flex-col md:flex-row justify-between">
              <span class="md:font-semibold">Contraseña</span>
              <span class="font-bold md:font-normal">********</span>
              <a-button
                type="ghost"
                class="mt-2 md:mt-0"
                shape="round"
                @click="showModal('password')"
              >
                Cambiar
              </a-button>
            </a-list-item>

            <a-list-item class="flex flex-col md:flex-row justify-between">
              <span class="md:font-semibold">Roles</span>
              <a-tag color="cyan" class="px-3 py-1" v-for="rol in roles" :key="rol">
                {{ rol }}
              </a-tag>
            </a-list-item>
          </a-list>
        </div>
      </div>
    </div>
  </div>
  <MiCuentaEditPassword
    :idusuario="idusuario"
    v-if="isVisible && typeEdit === 'password'"
    @closeModal="closeModal"
  />
  <MiCuentaEdit
    v-else-if="isVisible && typeEdit !== 'password'"
    @closeModal="closeModal"
    :usuario="usuario"
    :idusuario="idusuario"
    :typeEdit="typeEdit"
  />
</template>

<script>
import { UserOutlined } from "@ant-design/icons-vue";
import MiCuentaEditPassword from "./MiCuentaEditPassword.vue";
import { ref, computed, reactive } from "vue";
import MiCuentaEdit from "./MiCuentaEdit.vue";
import { shortName } from "@/utils";
import { useStore } from "vuex";
import moment from "moment";

export default {
  name: "MiCuenta",
  components: {
    MiCuentaEdit,
    MiCuentaEditPassword,
    // icons
    UserOutlined
  },
  setup() {
    const isVisible = ref(false);
    const typeEdit = ref("");
    const store = useStore();

    const {
      idusuario,
      usuario: documentNumber,
      apellido,
      nombre,
      fechaingreso,
      celular,
      correo,
      roles
    } = store.state.user.usuario;

    const usuario = reactive({
      celular,
      correo
    });

    const showModal = (type) => {
      typeEdit.value = type;
      isVisible.value = true;
    };

    const closeModal = () => {
      isVisible.value = false;
    };

    const shortNameUser = computed(() => {
      return shortName(nombre, apellido);
    });

    const miembroDesde = computed(() => {
      return moment(fechaingreso).format("DD MMM YYYY");
    });

    const fullName = computed(() => {
      return nombre + " " + apellido;
    });

    return {
      isVisible,
      showModal,
      closeModal,
      usuario,
      shortNameUser,
      miembroDesde,
      fullName,
      typeEdit,
      idusuario,
      documentNumber,
      roles
    };
  }
};
</script>

<style></style>
