<template>
  <a-modal
    :visible="true"
    title="Cambiar la contraseña"
    @ok="handleOk"
    @cancel="handleCancel"
    @close="closeModal"
    class="max-w-sm"
    okText="Guardar"
    :maskClosable="false"
  >
    <a-form :model="formState" ref="formRef" :rules="rules" layout="vertical">
      <a-form-item name="contra_old">
        <a-input-password
          v-model:value="formState.contra_old"
          type="password"
          placeholder="Contraseña actual"
        >
          <template #prefix><LockOutlined style="color: rgba(0, 0, 0, 0.25)" /> </template>
        </a-input-password>
      </a-form-item>

      <a-form-item has-feedback name="contra_new">
        <a-input-password
          v-model:value="formState.contra_new"
          placeholder="Nueva contraseña"
          type="password"
        >
          <template #prefix><LockOutlined style="color: rgba(0, 0, 0, 0.25)" /> </template>
        </a-input-password>
      </a-form-item>
      <a-form-item has-feedback name="confirm_password">
        <a-input-password
          v-model:value="formState.confirm_password"
          placeholder="Repetir nueva contraseña"
          type="password"
        >
          <template #prefix><LockOutlined style="color: rgba(0, 0, 0, 0.25)" /></template>
        </a-input-password>
      </a-form-item>
      <a-alert v-if="hasError" class="mt-4" :description="hasError" type="error" />
    </a-form>
  </a-modal>
</template>

<script>
import { reactive, toRaw, ref } from "vue";
import { LockOutlined } from "@ant-design/icons-vue";
import { hasNumber, hasMayus } from "@/utils";
import { notification } from "ant-design-vue";
import UserApi from "@/api/user";

export default {
  components: {
    // icons
    LockOutlined
  },
  emits: ["closeModal"],
  setup(props, { emit }) {
    const formState = reactive({
      contra_old: "",
      contra_new: "",
      confirm_password: ""
    });
    const formRef = ref();
    const hasError = ref(false);

    const closeModal = () => {
      emit("closeModal");
    };

    const handleOk = () => {
      formRef.value
        .validate()
        .then(async () => {
          const { contra_old, contra_new } = toRaw(formState);
          UserApi.cambiarContra({ contra_old, contra_new })
            .then((res) => {
              console.log(res);
              notification.success({
                message: "Operación exitosa",
                description: "La contraseña se ha actualizado correctamente."
              });
              closeModal();
            })
            .catch((err) => {
              hasError.value = err.message;
            });
        })
        .catch((err) => console.log(err));
    };

    const handleCancel = () => {
      closeModal();
    };

    let validatePass = async (rule, value) => {
      if (value === "") {
        formState.confirm_password = "";
        return Promise.reject("La contraseña actual es requerida");
      } else if (formState.confirm_password !== "") {
        formRef.value.validateFields("contra_new");
      } else if (!hasNumber(value)) {
        return Promise.reject("Debe contener al menos un número");
      } else if (!hasMayus(value)) {
        return Promise.reject("Debe contener al menos un caracter en mayúscula");
      } else if (value.length < 6) {
        return Promise.reject("La cantidad mínima de caracteres es de 6");
      } else {
        return Promise.resolve();
      }
    };

    let validatePass2 = async (rule, value) => {
      if (value === "") {
        return Promise.reject("Repetir la nueva contraseña es requerida");
      } else if (value !== formState.contra_new) {
        return Promise.reject("Las contraseñas no coinciden");
      } else {
        return Promise.resolve();
      }
    };

    const rules = {
      contra_old: [
        { required: true, message: "La contraseña actual es requerida", trigger: "blur" }
      ],
      contra_new: [
        {
          required: true,
          validator: validatePass,
          trigger: "change"
        },
        { max: 20, message: "Deben ser a lo mucho 20 caracteres" }
      ],
      confirm_password: [
        {
          validator: validatePass2,
          trigger: "change"
        },
        { max: 20, message: "Deben ser a lo mucho 20 caracteres" }
      ]
    };

    return {
      handleOk,
      closeModal,
      handleCancel,
      formState,
      formRef,
      rules,
      hasError
    };
  }
};
</script>
